<template>
  <print-table v-if="form.id" :form="form" :historyRecord="historyRecord"
               title="入职申请">
    <template #form>
      <div class="info_item">
        <div>姓名</div>
        <div>{{form.staffName}}</div>
      </div>
      <div class="info_item">
        <div>手机号</div>
        <div>{{form.mobile}}</div>
      </div>
      <div class="info_item">
        <div>聘用部门</div>
        <div>{{form.sysDeptName.deptName || '无'}}</div>
      </div>
      <div class="info_item">
        <div>聘用岗位</div>
        <div>{{form.sysPositionNameList && form.sysPositionNameList.map(e => e.positionName).join(',')}}</div>
      </div>
      <div class="info_item">
        <div>兼职岗位</div>
        <div>{{form.ptPosition}}</div>
      </div>
      <div class="info_item">
        <div>职级</div>
        <div>{{ form.sysRankLevelNameList && form.sysRankLevelNameList.map(e => e.content).join(',') }}</div>
      </div>
      <div class="info_item">
        <div>拟入职时间</div>
        <div>{{form.preEntryTime}}</div>
      </div>
      <div class="info_item">
        <div>工作地点</div>
        <div>{{form.workName}}</div>
      </div>

    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";
import {formatTime} from "../../dynamicForm/utils/utils";

export default {
  name: "print",
  components: {PrintTable},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      this.$api.personnel.inductionApprovalGet(this.id).then((res) => {
        if (res.data) {
          if (res.data.hasHistory) {
            this.getHistoryActivity(res.data.id);
          }
          this.form = res.data;
        }
      });
    },
    getHistoryActivity(id){
      this.$api.personnel
          .inductionApprovalGetHistory({ id })
          .then(({ data }) => {
            if (data) {
              this.historyRecord = data.list;
            }
          });
    }
  }

}
</script>

<style scoped lang="scss">


</style>