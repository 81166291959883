<template>
  <div class="print-wapper">
    <div class="content">
      <div v-if="!isPrinting">
        <el-button type="primary" size="medium" class="btn_print mgl20" @click="printData" >打印</el-button>
        <el-button size="medium" class="btn_print  mgl20" @click="back">返回</el-button>
      </div>

      <div class="title">{{title}}</div>
      <div class="table">
        <div class="form-info">
          <slot name="form"></slot>
        </div>
        <!--   这个template 需要优化     -->
        <div class="flow">
          <div class="flow_col flow-title">申请流程</div>
          <div class="flow_col flow-detail">
            <div v-for=" item in flowData">
              <print-flow :one="item"></print-flow>
            </div>
          </div>
        </div>
<!--        <div class="flow history-flow" v-for="hisFlow in historyRecord">-->
<!--          <div class="flow_col flow-title">历史流程</div>-->
<!--          <div class="flow_col flow-detail">-->
<!--            <div v-for="one in hisFlow.activityModel.activitiNodeDetails">-->
<!--                <print-flow :one="one"></print-flow>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="footer mgt12 clearfix">
          <div :class="[printTime?'visible':'','float-left']">打印时间：{{printTime}}</div>
          <div class="float-right">打印人：{{userInfo&&userInfo.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatTime} from "../pages/businessModule/dynamicForm/utils/utils";
import {foldNodeList} from "../utils/utils";
import PrintFlow from "./print-flow";
export default {
  name: "print-table",
  props:{
    form:{
      type:Object,
      value: {}
    },
    historyRecord:{
      type:Array,
      value:[]
    },
    title:'',
  },
  components:{
    PrintFlow
  },
  watch:{
    historyRecord(val){
      this.historyRecordData = val.map(e=>e.activityModel.activitiNodeDetails=foldNodeList(e.activityModel.activitiNodeDetails))
    }
  },
  data(){
    return {
      isPrinting:false,
      printTime:'',
      historyRecordData:[],
      flowData:[],
      userInfo:null
    }
  },
  mounted(){
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.flowData=foldNodeList(this.form.activityModel.activitiNodeDetails)
  },
  methods:{
    printData(){
      this.isPrinting = true;
      this.printTime = formatTime(new Date(),'Y-M-D h:m')
      // 页面
      setTimeout(()=>{
        this.isPrinting = false;
        this.printTime = '';
      },500)
      setTimeout(()=>{
        window.print();
      },0)
    },
    foldNodeList(list){
      return foldNodeList(list)
    },

    back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@mixin col(){
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin: -1px;
  padding: 10px;
}
.print-wapper{
  background: #fff;
  width: 100%;
  height: 100%;
  .content{
    padding: 10px;
    margin: auto;
    width: 1000px;
    box-sizing:border-box;
    .title{
      margin-top:20px;
      font-size: 18px;
      text-align: center;
    }
    .table{
      margin-top: 10px;
      .form-info{
        display: flex;
        border: 1px solid #CCCCCC;
        flex-direction: column;
        .info_item{
          display: flex;
          flex-direction: row;
          div{
            @include col;
            &:first-child{
              width: 33.3%;
            }
            &:last-child{
              flex: 1;
            }
          }
        }
      }
      .flow{
        border: 1px solid #CCCCCC;
        display: flex;
        flex-direction: row;
        margin-top: -1px;
        .flow-title{
          width: 33.3%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          @include col;
        }
        .flow-detail{
          flex:1;

        }
      }
    }
    .footer{
      .float-left {
        visibility: hidden;
        &.visible{
          visibility: visible
        }
      }
    }
  }
}

</style>